'use strict';

import http from '@/utils/http';

export default {
  getList(params) {
    return http.get(
      '/connections_treasure_box/user/novice-menu-record/queryNoviceMenuList/' +
        params.userId
    );
  },
};
