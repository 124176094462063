<template>
  <div class="mission-center">
    <div class="contain">
      <span class="contain-title">任务中心</span>
      <div class="mission-count">
        <div class="icon-area">
          <img src="@/assets/images/mission-center/mission-icon.png" alt="" />
        </div>
        <span class="text">完成所有任务将获得400个人脉宝盒金币</span>
        <div class="count-area">
          <span>{{ finishTotalMenu }}/{{ totalMenu }}</span>
        </div>
      </div>
      <div class="row">
        <div class="mission-list">
          <span class="list-header">充分利用人脉宝盒</span>
          <template v-for="(item, index) in missionList" :key="index">
            <div class="mission-item" @click="changeMission(index)">
              <img
                v-if="item.isFinish"
                src="@/assets/images/mission-center/complete-icon.png"
                alt=""
              />
              <img
                v-else
                src="@/assets/images/mission-center/uncomplete-icon.png"
                alt=""
              />
              <span
                class="mission-title"
                v-if="item.isFinish === 0"
                >{{
                  item.title + '(' + item.finishCount + '/' + item.count + ')'
                }}</span
              >
              <span class="mission-title" v-else>{{
                item.title + '(' + item.statusDes + ')'
              }}</span>
              <div class="mission-reward">
                <span>+{{ item.giveGold }}</span>
                <img src="@/assets/images/play/jinbi.png" alt="" />
              </div>
            </div>
          </template>
        </div>
        <div class="video-list">
          <template v-for="(item, index) in missionList" :key="index">
            <div
              class="video-item"
              v-if="activeIndex === index"
              @click="videoPlay"
            >
              <span class="mission-title">{{ item.title }}</span>
              <span class="mission-intro">{{ item.description }}</span>
              <div class="video-contain">
                <img
                  v-show="!isVideoPlay"
                  class="video-poster"
                  :src="item.pictureUrl"
                  alt=""
                />
                <img
                  v-show="!isVideoPlay"
                  class="play-btn"
                  src="@/assets/images/mission-center/play-btn.png"
                />
                <video ref="videoRef" :src="item.pcUrl" controls></video>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import missionApi from '@/api/mission';

const store = useStore();

const finishTotalMenu = ref(0);
const totalMenu = ref(0);
const missionList = ref([]);
const activeIndex = ref(0);
const isVideoPlay = ref(false);
const videoRef = ref();

const changeMission = index => {
  activeIndex.value = index;
  isVideoPlay.value = false;
};

const videoPlay = () => {
  videoRef.value[0].play();
  isVideoPlay.value = true;
};

onMounted(() => {
  missionApi
    .getList({
      userId: store.state.account.user.id,
    })
    .then(res => {
      finishTotalMenu.value = res.finishTotalMenu;
      totalMenu.value = res.totalMenu;
      missionList.value = res.menuList;
    });
});
</script>

<style lang="less" scoped>
.mission-center {
  width: 100%;
  height: 100%;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .contain {
    width: 100%;
    height: 100%;
    padding: 40px 52px;
    box-sizing: border-box;
    background: #ffffff;

    .contain-title {
      color: #333333;
      font-size: 24px;
      font-weight: bold;
      line-height: 1;
    }

    .mission-count {
      width: 440px;
      margin-top: 26px;
      padding: 12px 16px;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      background: #fff7ea;
      border-radius: 4px;

      .icon-area {
        width: 40px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        background: #ffffff;

        img {
          width: 16px;
          height: 16px;
          object-fit: cover;
        }
      }

      .text {
        margin-left: 16px;
        color: #ff7b00;
        font-size: 14px;
        font-weight: 400;
      }

      .count-area {
        width: 32px;
        height: 24px;
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ff7b00;
        border-radius: 4px;

        span {
          color: #ffffff;
          font-size: 10px;
          font-weight: 400;
        }
      }
    }

    .row {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 80px;
    }

    .mission-list {
      width: 440px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;

      .list-header {
        margin-top: 24px;
        color: #3f4557;
        font-size: 16px;
        font-weight: 400;
      }

      .mission-item {
        width: 100%;
        padding: 13px 17px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        border: 1px solid #cfd6e8;
        border-radius: 4px;
        background: #ffffff;
        cursor: pointer;

        &:hover {
          border: 1px solid #ff7b00;
        }

        img {
          width: 14px;
          height: 14px;
          object-fit: cover;
        }

        span {
          color: #333333;
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
        }

        .mission-title {
          margin-left: 9px;
        }

        .mission-reward {
          margin-left: auto;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
        }
      }
    }

    .video-list {
      width: 520px;
      padding-top: 60px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;

      .video-item {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;

        .mission-title {
          color: #333333;
          font-size: 16px;
          font-weight: 400;
          line-height: 1.5px;
        }

        .mission-intro {
          margin-bottom: 8px;
          color: #6b7592;
          font-size: 12px;
          font-weight: 400;
          line-height: 14px;
        }

        .video-contain {
          width: 520px;
          height: 300px;
          position: relative;

          video {
            width: 100%;
            height: 100%;
          }

          .video-poster {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
          }

          .play-btn {
            width: 24px;
            height: 24px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 3;
          }
        }
      }
    }
  }
}
</style>
